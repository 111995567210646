import { BasicoApi } from '@common/api';
const KanbanService = {
  listar: function (resource) {
    return BasicoApi.get()(`/Kanban?SetorId=${resource}`);
  },
  listarOS: function (resource) {
    return BasicoApi.get()(`/Kanban?KanbanConfiguracaoId=${resource}`);
  },
};

export default KanbanService;
