<template>
  <div class="container-kanban">
    <cabecalho
      :lista-atualizada-em="listaAtualizadaEm"
      :loading="loading"
      :setor-selecionado="setorSelecionado"
      @atualizaKanban="atualizaKanban"
      @esconderMenuSuperior="esconderMenuSuperior()"
      :informacoesEmLinha="true"
    />
    <draggable
      v-if="kanban?.colunas?.length > 0"
      style="overflow-x: scroll"
      class="kanban-board"
      draggable="false"
    >
      <div
        v-for="(coluna, index) in kanban.colunas"
        :key="index"
        class="kanban-column"
        :style="{ backgroundColor: coluna.corHexaFundoColuna }"
        :class="{ 'single-card': coluna.cartoes.length === 1 }"
      >
        <div
          class="kanban-header"
          :style="{ color: coluna.corHexaFonteCabecalhoCartao }"
        >
          <span :style="{ fontSize: `${(coluna.tamanhoFonte * 1) / 10}rem` }">
            {{ coluna.nome }} ({{ coluna.cartoes.length }})
          </span>
        </div>

        <div class="kanban-cards">
          <div
            v-for="(subcoluna, subcolunaIndex) in dividirCartoesEmSubcolunas(
              coluna.cartoes,
              coluna.quantidadeCartoesColuna,
              coluna.quantidadeCartoesLinha
            )"
            :key="subcolunaIndex"
          >
            <div
              v-for="(cartao, cartaoIndex) in subcoluna"
              :key="cartaoIndex"
              class="kanban-card"
            >
              <cartao
                :setor="setor"
                :setor-id="setorId"
                :cartao="cartao"
                :background="cartao.corHexaFundoCartao"
                :cor-fonte="cartao.corHexaFonteCartao"
                :redireciona="true"
                :font-size="`${(coluna.tamanhoFonte * 1) / 10}rem`"
              />
            </div>
          </div>
        </div>
      </div>
    </draggable>

    <spinner
      v-else
      :message="$t('modulos.kanban_programacao_manual.mensagem_nao_ha_dados')"
    />
  </div>
</template>

<script>
import KanbanConfiguracaoSetorGeralService from "@common/services/cadastros/KanbanConfiguracaoSetorGeralService";
import "vue-kanban/src/assets/kanban.scss";
import draggable from "vuedraggable";
import Cabecalho from "./components/Cabecalho.vue";
import Cartao from "@/components/kanban/cards/Cartao.vue";
import KanbanService from "@/common/services/cadastros/KanbanService";
import Spinner from "./components/Spinner.vue";
import helpers from '@common/utils/helpers';

export default {
  name: "Kanban",
  components: {
    draggable,
    Spinner,
    Cartao,
    Cabecalho,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      intervalo: null,
      setorSelecionado: null,
      listaAtualizadaEm: null,
      setorId: "",
      setor: "",
      kanban: {
        colunas: null,
      },
      regraCartao: {
        corHexaFundoCartao: "",
        corHexaFonteCartao: "",
      },
      listaRegras: null,
      listaCartoes: [],
      enumSituacoes: helpers.SituacaoOrdemServicoEnum,
    };
  },

  async mounted() {
    await this.$store.dispatch(
      "Layout/alterarTituloPagina",
      this.$t("modulos.kanban.titulos.titulo")
    );
    await this.verificarSeTemSetor();
    await this.initList();
  },
  beforeDestroy() {
    clearInterval(this.intervalo);
  },

  methods: {
    getCorHexaFundoCartao(code) {
      const style = this.listaCartoes.find(card => card.codigo == code)
      return style.corHexaFundoCartao
    },
    getCorHexaFonteCartao(code) {
      const style = this.listaCartoes.find(card => card.codigo == code)
      return style.corHexaFonteCartao
    },
    async initList() {
      this.intervalo = setInterval(async () => {
        await this.listarRegistros();
        await this.listarRegraDeCartoes();
      }, 300000);
    },
    async listarRegraDeCartoes(paginaAtual = 1, porPagina = 1000) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        setorId: this.setorId,
      };
      this.$store.dispatch("Layout/iniciarCarregamento");

      try {
        const res = await KanbanConfiguracaoSetorGeralService.listar(
          parametros
        );
        this.listaRegras = res.data.items;
      } finally {
        this.$store.dispatch("Layout/terminarCarregamento");
      }
    },

    verificaRegraDoCartao(cartoes) {
      return cartoes.map((cartao) => {
        const setRegraCartao = ({ corHexaFundoCartao, corHexaFonteCartao }) => {
          cartao.corHexaFundoCartao = corHexaFundoCartao;
          cartao.corHexaFonteCartao = corHexaFonteCartao;
        };

        let stopRegras = false;

        this.listaRegras.some((regra) => {
          if (regra.campoRelacional == "Situacao") {
            let regraAplicada = this.calculaSituacao(regra, cartao);
            if (regraAplicada) {
              setRegraCartao(regraAplicada);
              stopRegras = true;
            }
          }
          if (regra.campoRelacional == "Prioridade") {
            let regraAplicada = this.calculaPrioridade(regra, cartao);
            if (regraAplicada) {
              setRegraCartao(regraAplicada);
              stopRegras = true;
            }
          }
          if (regra.campoRelacional == "NumeroDiasDataEntrega") {
            let regraAplicada = this.calculaNumeroDiasParaDataEntrega(regra, cartao);
            if (regraAplicada) {
              setRegraCartao(regraAplicada);
              stopRegras = true;
            }
          }

          return stopRegras;
        });

        return cartao
      });
    },

    calculaNumeroDiasParaDataEntrega(regra, cartao) {
      const dataAtual = new Date();
      const dataEntrega = new Date(cartao.prazo);
      const diffTime = Math.abs(dataEntrega - dataAtual);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      switch (regra.operadorRelacional) {
        case 'MenorIgual':
          return diffDays <= regra.numeroDiasParaDataEntrega ? regra : null;
        case 'MaiorIgual':
          return diffDays >= regra.numeroDiasParaDataEntrega ? regra : null;
        case 'Menor':
          return diffDays < regra.numeroDiasParaDataEntrega ? regra : null;
        case 'Maior':
          return diffDays > regra.numeroDiasParaDataEntrega ? regra : null;
        case 'Igual':
          return diffDays === regra.numeroDiasParaDataEntrega ? regra : null;
        case 'Diferente':
          return diffDays !== regra.numeroDiasParaDataEntrega ? regra : null;
        default:
          return null;
      }
    },

    calculaPrioridade(regra, cartao) {
      if (!cartao.prioridade) return null;

      switch (regra.operadorRelacional) {
        case 'MenorIgual':
          return cartao.prioridade <= regra.prioridade.sequencia ? regra : null;
        case 'MaiorIgual':
          return cartao.prioridade >= regra.prioridade.prioridade ? regra : null;
        case 'Menor':
          return cartao.prioridade < regra.prioridade.prioridade ? regra : null;
        case 'Maior':
          return cartao.prioridade > regra.prioridade.prioridade ? regra : null;
        case 'Igual':
          return cartao.prioridade === regra.prioridade.prioridade ? regra : null;
        case 'Diferente':
          return cartao.prioridade !== regra.prioridade.prioridade ? regra : null;
        default:
          return null;
      }
    },

    calculaSituacao(regra, cartao) {
      const situacaoCartao = this.enumSituacoes.find(situacao => situacao.name === cartao.situacao);
      const situacaoRegra = this.enumSituacoes.find(situacao => situacao.name === regra.situacao);

      if (!situacaoCartao || !situacaoRegra) {
        return null;
      }

      switch (regra.operadorRelacional) {
        case 'Igual':
          return situacaoCartao.value === situacaoRegra.value ? regra : null;
        case 'Diferente':
          return situacaoCartao.value !== situacaoRegra.value ? regra : null;
        case 'Menor':
          return situacaoCartao.value < situacaoRegra.value ? regra : null;
        case 'MenorIgual':
          return situacaoCartao.value <= situacaoRegra.value ? regra : null;
        case 'Maior':
          return situacaoCartao.value > situacaoRegra.value ? regra : null;
        case 'MaiorIgual':
          return situacaoCartao.value >= situacaoRegra.value ? regra : null;
        default:
          return null;
      }
    },

    verificarSeTemSetor() {
      if (this.$route.params.setor) {
        this.setorSelecionado = this.$route.params;
        this.setorId = this.$route.params.setorId;
        this.setor = this.$route.params.setor;
      }
    },

    listarRegistros: function () {
      this.$store.dispatch("Layout/iniciarCarregamento");
      KanbanService.listar(this.setorId)
        .then((res) => {
          this.loading = true;
          this.kanban.colunas = this.agruparCartoesEmColunas(res.data.colunas);
          this.listaCartoes = this.listarCartoes(res.data.colunas);
          this.listaAtualizadaEm = this.formatarData(new Date());
          this.loading = false;
        })
        .then(async () => {
          await this.verificarSeTemSetor();
        })
        .finally(() => {
          this.$store.dispatch("Layout/terminarCarregamento");
        });
    },

    listarCartoes(colunas) {
      const cartoes = [];
      colunas.forEach((coluna) => {
        coluna.cartoes.forEach((cartao) => {
          cartoes.push(cartao);
        });
      });
      return cartoes;
    },

    formatarData(data) {
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      var horas = String(data.getHours()).padStart(2, "0");
      var minutos = String(data.getMinutes()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}h${minutos}`;
    },

    dividirCartoesEmSubcolunas(
      cartoes,
      quantidadeCartoesColuna,
      quantidadeCartoesLinha
    ) {
      const maxCartoes = quantidadeCartoesColuna * quantidadeCartoesLinha;
      const subcolunas = [];
      for (
        let i = 0;
        i < Math.min(cartoes.length, maxCartoes);
        i += quantidadeCartoesColuna
      ) {
        subcolunas.push(cartoes.slice(i, i + quantidadeCartoesColuna));
      }
      return subcolunas;
    },

    limparColunaVazia: function () {
      this.kanban.colunas = this.kanban.colunas.filter(
        (coluna) => coluna.cartoes.length > 0
      );
    },
    atualizaKanban: function (setorId, setor) {
      this.setorId = setorId;
      this.setor = setor;
      this.listarRegistros();
      this.listarRegraDeCartoes();
    },

    agruparCartoesEmColunas(colunas) {
      const agrupadas = [];

      colunas.forEach(async (coluna) => {
        coluna.cartoes = await this.verificaRegraDoCartao(coluna.cartoes)

        const ultimaColuna =
          agrupadas.length > 0 ? agrupadas[agrupadas.length - 1] : null;

        if (ultimaColuna && ultimaColuna.nome === coluna.nome) {
          ultimaColuna.cartoes = ultimaColuna.cartoes.concat(coluna.cartoes);
          ultimaColuna.quantidadeCartoesColuna = coluna.quantidadeCartoesLinha;
          ultimaColuna.quantidadeCartoesLinha = coluna.quantidadeCartoesColuna;
        } else {
          agrupadas.push({ ...coluna });
        }
      });

      return agrupadas;
    },
  },
};
</script>

<style lang="scss">
.container-kanban {
  min-height: calc(100vh - 150px);
}
.kanban-board {
  display: flex;
  gap: 20px;
  padding-bottom: 16px;
}

.kanban-column {
  gap: 8px;
  min-height: calc(100vh - 280px);
  padding: 0;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.card {
  height: 100%;
}

.kanban-cards {
  margin-top: 4px;
  width: max-content;
  padding: 8px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.kanban-card {
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
  border: 1px solid gray;
  min-width: 190px;
  max-width: 190px;
  margin-bottom: 8px;
}
.kanban-header {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 8px;

  span {
    max-width: 180px;
    width: 200px;
    text-align: center;
    font-weight: 600;
    opacity: 0.9;
  }
}

@media screen and (max-width: 860px) {
  .kanban-board {
    flex-direction: column;
  }

  .kanban-column,
  .single-card {
    min-width: 100% !important;
    width: 100% !important;
    margin-right: 10px;
  }
}
</style>
