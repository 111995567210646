<template>
  <div class="custom-card" outlined>
    <div :class="['header', { 'header-inline': informacoesEmLinha }]">
      <div class="titulo-pagina">
        {{ $t('modulos.kanban_configuracao.titulos.kanban') }}
      </div>
      <div class="container-header">
        <div class="container-wrapper">
          <input-setor
            v-model="setor"
            class="col-12 col-md-2 input-setor-modal"
            :placeholder="$t('geral.inputs.selecione')"
            :participante-id="participanteSelecionado"
            :multiplos="false"
            @emiterEvento="eventoHandler"
            :sem-label="true"
          />
        </div>
        <div :class="['d-flex align-center container-update', { 'no-margin-top': informacoesEmLinha }]">
          <span class="text">
            {{ $t('modulos.kanban_configuracao.titulos.atualizado_em') }}
            {{ listaAtualizadaEm }}
          </span>
          <div v-if="loading" class="card flex justify-content-center">
            <progress-spinner
              style="width: 20px; height: 20px"
              strokeWidth="4"
              fill="var(--surface-ground)"
              animationDuration=".5s"
              aria-label="Custom ProgressSpinner"
            />
          </div>
          <span v-else @click="atualizaKanban" class="d-flex mr-2 ml-4">
            <img
              src="../../../../../assets/icons/load.svg"
              alt="refresh-icon"
              class="pointer"
              style="width: 20px; height:20px"
            />
          </span>
          <button
            @click="configuracaoKanban"
            to="/kanban-configuracao"
            class="d-flex"
          >
            <img
              src="../../../../../assets/icons/settings.svg"
              alt="settings-icon"
              class="pointer"
              style="width: 20px; height:20px"
            />
          </button>
          <span @click="esconderMenuSuperior" class="d-flex mr-2 ml-4">
            <img
              src="../../../../../assets/icons/eye.svg"
              alt="eye-icon"
              class="pointer"
              style="width: 20px; height:20px;"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetorService from '@common/services/cadastros/SetorService';
import { DropdownModel } from '../../../../../common/models/geral/DropdownModel';
import { mapGetters } from 'vuex';
import ProgressSpinner from 'primevue/progressspinner';
import { InputSetor } from '@components/inputs';
import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js';

export default {
  name: 'Cabecalho',
  components: {
    ProgressSpinner,
    InputSetor,
  },
  props: {
    setorSelecionado: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    listaAtualizadaEm: {
      type: String,
      default: '',
    },
    informacoesEmLinha: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setorId: null,
      setor: null,
      options: {
        setores: [],
      },
      emitterGlobal: useEmitterBus(),
    };
  },

  methods: {
    eventoHandler(value) {
      this.setorId = value.value;
      this.setor = value;
      this.atualizaKanban();
    },
    atualizaKanban() {
      this.$emit('atualizaKanban', this.setorId, this.setor);
    },

    configuracaoKanban() {
      this.$router.push({
        name: 'kanban-configuracao',
        params: {
          setorId: this.setorId,
        },
      });
    },

    setorInicialmenteSelecionado() {
      if (this.usuario.setorId) {
        this.setorId = this.usuario.setorId;
        this.setor = this.options.setores.find(
          (setor) => setor.value === this.setorId
        );
        if (this.setor) return;
      }

      this.setorId = this.options.setores[0].value;
      this.setor = this.options.setores[0]
    },

    esconderMenuSuperior() {
      this.emitterGlobal.emit(EventTypes.ToggleMenuSuperior, null);
    },
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteSelecionado']),
    ...mapGetters('Autenticacao', ['usuario']),
  },

  mounted() {
    const params = {
      ParticipanteId: this.participanteSelecionado,
      AmountPerPage: 20000,
    };
    SetorService.listarSetoresParticipantesConta(params).then((response) => {
      this.options.setores = new DropdownModel(response.data.items);
      this.setorInicialmenteSelecionado();
      if (this.$route.params && Object.keys(this.$route.params).length > 0) {
        this.setor = this.$route.params;
        this.setorId = this.$route.params.value;
      }
      this.atualizaKanban();
    });
  },
};
</script>

<style scoped lang="scss">
.headline {
  margin-bottom: 12px;
}
.text {
  color: #344054 ;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px;
  display: flex;
  margin-left: 4px;
  margin-top: 4px;
}

.container-update {
  margin-top: 1.6rem;
}

.container-wrapper,
.input-setor-modal {
  padding: 0 !important;
}

.titulo-pagina {
  color: #344054 ;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 20px;
  line-height: 32px;
}

.container-header {
  margin: 12px 0 16px 0;
}

.container-header,
.container-wrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

@media (min-width: 960px) {
  .col-md-2 {
    max-width: 350px !important;
    flex: none;
  }
  .col-12 {
    max-width: 350px !important;
  }
}

@media (max-width: 960px) {
  .col-md-2 {
    max-width: 350px !important;
    flex: none;
  }
  .col-12 {
    max-width: 350px !important;
  }
}

@media screen and (max-width: 826px) {
  .container-header {
    flex-direction: column;
    align-items: start;
  }
  .container-wrapper {
    display: block;
    width: 100%;
  }

  .text {
    margin-right: 0;
    margin-left: 0;
  }
  .container-update {
    margin: 12px 0 12px;
  }
  .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-inline {
  flex-direction: row;
  align-items: center;
}

.header-inline .titulo-pagina {
  margin-right: 16px;
}

.no-margin-top {
  margin-top: 0;
}
</style>
